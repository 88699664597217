import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = sanityClient({
  projectId: 't110z3yf',
  dataset: 'production',
  useCdn: true,
});

export const imageUrl = (source, quality, width) => {
  return imageUrlBuilder(client).image(source).quality(quality).width(width).auto('format');
};

export default client;
