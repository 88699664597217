import BlockContent from '@sanity/block-content-to-react';
import {H2, H3, Blockquote, P, Img, Video} from './webComponents';
import {imageUrl} from '../lib/sanity';
import {LazyLoadComponent} from 'react-lazy-load-image-component';

const BlockRenderer = ({node: {style = 'normal', _type, ...t}, children}) => {
  if (_type === 'video') {
    return <Video {...t} />;
  } else if (_type === 'image') {
    return (
      <LazyLoadComponent>
        <Img alt='' src={imageUrl({style, _type, ...t}, 80, 500)} />
      </LazyLoadComponent>
    );
  } else {
    switch (style) {
      case 'h2':
        return <H2>{children}</H2>;
      case 'h3':
        return <H3>{children}</H3>;
      case 'blockquote':
        return <Blockquote>{children}</Blockquote>;
      default:
        return <P>{children}</P>;
    }
  }
};

const Content = ({content}) => {
  return <BlockContent blocks={content} serializers={{block: BlockRenderer}} />;
};

export default Content;
